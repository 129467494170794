.so-address-select {
    &__flattern {
        .ant-select-tree-list-holder-inner .ant-select-tree-treenode:first-child {
            border-bottom: 1px solid #eee;
            &.so-address-select__name {
                border-bottom: none;
            }
        }
    }
    &__name {
        &:hover {
            background-color: #fff;
        }
        .ant-select-tree-checkbox {
            display: none;
        }
        .ant-select-tree-node-content-wrapper {
            cursor: auto !important;
        }
        .ant-select-tree-title{
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
    &__tag.ant-tag {
        margin-right: 3px;
        background-color: #f7f7f7;
        border-color: #f0f0f0;
    }
    .ant-select-tree-node-content-wrapper {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .ant-select-arrow {
        color: rgba(0, 0, 0, 0.38);
    }

}
