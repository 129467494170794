@import '~@yeastar/pc-web-ui/src/style/variables.less';
@b: create-schedule;
.@{b} {
    display: flex;
    &__defaultDisplay {
        height: 32px;
        padding: 7px 11px;
        line-height: 16px;
        color: @white;
        border: 1px solid @white;
        box-shadow: 0 -2px 4px 0 rgba(0, 110, 225, 0.05), 0 2px 6px 6px rgba(0, 110, 225, 0.05), 0 2px 6px 0 rgba(0, 110, 225, 0.1);
        border-radius: 16px;
        font-size: @font-size-base;
        cursor: pointer;
        .anticon {
            margin-right: 4px;
        }
        &:hover {
            border-color: #ffffff99;
        }
    }
    &__dropdown {
        border-radius: @border-radius-md;
        padding: 8px;
        min-width: 216px !important;
        .@{b}__dropItem {
            height: 44px;
            padding: 11px 16px;
            border-radius: 10px;
            & + .@{b}__dropItem {
                margin-top: 4px;
            }
            .@{b}__bookIcon {
                display: inline-block;
                margin-right: 12px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: @border-radius-xs;
                .anticon {
                    margin-right: 0;
                }
            }
            .@{b}__room {
                background-color: @blue-1;
                color: @blue-base;
            }
            .@{b}__desk {
                background-color: @green-1;
                color: @green-base;
            }
            .@{b}__invite {
                background-color: @orange-1;
                color: @orange-base;
            }
        }
        .@{b}__dropItem + .ant-spin-nested-loading, .ant-spin-nested-loading + .@{b}__dropItem {
            margin-top: 4px;
        }
        .ant-dropdown-menu {
            padding: 8px;
        }
    }
    &__deskOverlay {
        position: absolute;
        z-index: 100;
        left: calc((100vw - 540px) / 2) !important;
        top: calc((100vh - 290px) / 2) !important;
    }
}
.desk-edit-popover-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    .ant-btn {
        min-width: 0;
        margin: 8px 0 8px 12px;
    }
}
