
/** 盒子水平垂直居中 */
.error-container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
}
