@import '~@yeastar/pc-web-ui/src/style/variables.less';

.extend-meeting {
    &-subtitle {
        display: inline-block;
        margin-bottom: 8px;
    }
    &-wrapper {
        position: relative;
        max-height: 720px;
        margin-bottom: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid @separator-color-base;
        border-radius: 4px;
        .ant-radio-group {
            width: 100%;
        }
        .ant-radio-wrapper {
            display: flex;
            width: 100%;
        }
        .ant-radio-wrapper span + span {
            flex: 1;
            padding-right: 0;
            text-align: end;
        }
    }
    &-item.ant-radio-wrapper-checked {
        background-color: @blue-1;
    }
    &-wrapper &-item {
        position: relative;
        padding: 8px @padding-md;
        transition: background-color .3s;
        &:not(:last-child)::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: @separator-color-base;;
            transform: translateX(16px);
            content: '';
        }
        &:hover:not(.ant-radio-wrapper-checked) {
            /** not选择器： 避免hover时的样式覆盖 **/
            background-color: @gray-1;
        }
    }
    &-label {
        // padding-right: 8px;
    }
    &-end-date {
        font-size: @font-size-xs;
        line-height: 22px;
    }
}
