@import '~@yeastar/pc-web-ui/src/style/variables.less';

.guest-select-dropdown {
    .selected-recent-tip {
        padding-left: 16px;
        color: @gray-7;
        font-size: 12px;
        line-height: 36px;
    }
}
.guest-select-dropdown-container {
    .ant-select-item-option {
        .guest-title {
            >.ant-row {
                line-height: 1;
            }
        }
        .ant-select-item-option-content {
            padding: 5px 0;
        }
        .ant-select-item-option-state {
            display: none;
        }
    }
}
.guest-main {
    padding-bottom: 2px;
    color: @gray-base;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 36px);
    &.single-name {
        padding-bottom: 0;
    }
}
.guest-sub {
    font-size: 12px;
}

.guest-box-tag.ant-tag {
    margin-right: 4px;
    background-color: rgba(0, 0, 0, 0.07);
    border: 0;

    .anticon {
        color: rgba(0, 0, 0, 0.6);
    }
}
