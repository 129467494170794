@import '~@yeastar/pc-web-ui/src/style/variables.less';

.user-tag {
    padding: 6px 8px;
    font-size: @font-size-xs;
    line-height: 18px;
    background-color: @gray-1;
    border-radius: 16px;
    & + .user-tag {
        margin-left: 8px;
    }
    img {
        margin-right: 4px;
        border-radius: 50%;
    }

    &__avatar {
        position: relative;
        display: inline-block;

        &-icon {
            position: absolute;
            right: -7px;
            bottom: -9px;
            z-index: 1;

            span[class="anticon"] {
                color: @green-base !important;
                font-size: 14px !important;
                background: #fff;
                border-radius: 50%;
            }
        }
    }
}
