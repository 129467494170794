// @import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~@yeastar/pc-web-ui/src/style/variables.less';

//主题色确定后还需按实际变量修改
@border-color-default: #f2f2f2;
@border-color-select: #006ee1;
@font-color-default: rgba(0, 0, 0, 0.6);
@font-color-select: #0054ba;
@background-color-default: #e6f6ff;
@box-shadow-default: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
@background-color-select: @border-color-select;
@background-color-new-time: #f63b3b;
@background-color-time-column: #fff;
@background-color-event: #eeeeee;
@font-color-event-select: @background-color-time-column;
@background-color-select-not: #858585;

@font-color-select-approval-pending: #fe8c00;
@background-color-default-approval-pending: #fff8e6;
@background-color-default-approval-pending: #fff8e6;

//主要是复写样式
.ys-calendar-meetings {
    display: flex;
    width: calc(100% + 48px);
    margin-left: -24px;
    height: calc(100% + 22px);

    .ys-calendar-container {
        flex: 1;
        width: 100%;
        height: 100%;
        //控制事件区域有最大值
        // max-width: 300px;
        .ys-calendar {
            //控制事件区域有最小值
            .rbc-time-content {
                // min-width: 138px;

                .rbc-multiple-horizontal-timezone {
                    position: sticky;
                    left: 0;
                    top: 0;
                    background-color: #fff;
                    border-right: #ddd;
                    z-index: 10;
                    margin-right: -1px;

                    &__wrapper {
                        display: flex;
                        max-width: 252px;
                        overflow: overlay;

                        .rbc-time-gutter {
                            position: unset;
                            border-left: 1px solid #f2f2f2;
                        }
                    }
                }
            }
            .rbc-timeslot-group {
                min-height: 60px;
                border-bottom: 1px solid @border-color-default;
            }
            .rbc-day-slot.rbc-time-column {
                // max-width: 300px;
                background-color: @background-color-time-column;
            }
            // 去掉右内边距，避免时间刚好文本显示全，却因为多余的边距以...显示出来
            .rbc-day-slot .rbc-event-label {
                padding-right: 0;
            }
            .rbc-time-header-content {
                // max-width: 300px;
                margin-right: 0px;
                margin-left: 0px;
                border-right: 1px solid @border-color-default;
                border-left: none;
            }
            .ys-calendar-Header {
                text-align: center;
            }
            .rbc-current-time-indicator {
                background-color: @background-color-new-time;
                width: calc(100% + 1px);
            }
            .rbc-time-view {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: auto;
                border: none;
            }

            .rbc-events-container {
                border: none !important;

                .rbc-event,
                .rbc-day-slot .rbc-background-event {
                    flex-flow: row wrap;

                    background: @background-color-event;
                    border: none;
                    filter: blur(10);
                    color: @font-color-default;
                    border-radius: 0;
                    .rbc-event-label {
                        width: 100%;
                        line-height: 18px;
                        font-size: 12px;
                    }
                    .rbc-event-label.rbc-event-label-short {
                        margin-top: -2px;
                        line-height: 12px;
                    }
                }
                .rbc-event > .rbc-event-label {
                    display: none !important;
                }
                .rbc-addons-dnd-resizable > .rbc-event-label {
                    display: none !important;
                }
            }
            .rbc-slot-selection {
                color: @font-color-select;
                background: @background-color-default;
                border: 1px solid @border-color-select !important;
                box-shadow: @box-shadow-default;
                filter: blur(10);
            }
            .rbc-event.rbc-selected,
            .rbc-day-slot .rbc-selected.rbc-background-event {
                background: @background-color-event;
            }
            .eventWrapper {
                .rbc-event {
                    position: static;
                    width: 100% !important;
                    height: 100% !important;
                    min-height: 10px !important;
                }

                .rbc-event.rbc-selected.ant-popover-open {
                    color: @font-color-event-select;
                    background: @background-color-select;
                }
                .ant-popover-open {
                    .rbc-event.rbc-selected {
                        color: @font-color-event-select;
                        background: @background-color-select;
                    }
                }
            }
            .eventWrapper.not-own {
                .rbc-event.rbc-selected.ant-popover-open {
                    color: @font-color-event-select;
                    background: @background-color-select-not;
                }
            }
            .eventWrapper.disable {
                .rbc-event,
                .rbc-day-slot .rbc-background-event {
                    background: rgba(0, 0, 0, 0.04);

                    // color: @font-color-event-select;
                }
                div {
                    background: rgba(0, 0, 0, 0.04);
                }
            }
            .eventWrapper.disable.roomDisable {
                > div {
                    .rbc-event, .rbc-event-content {
                        color: @font-color-default;
                        background: @background-color-event;
                        font-size: 12px;
                        line-height: 18.5px;
                    }
                    // 点击会议室禁用事件时的evt样式
                    &.ant-popover-open {
                        .rbc-event, .rbc-event-content {
                            color: #fff;
                            background: #006ee1;
                        }
                    }
                }
            }
            .rbc-event.rbc-addons-dnd-drag-preview {
                color: @font-color-select;
                background: @background-color-default;
                border: 1px solid @border-color-select;
                box-shadow: @box-shadow-default;
                z-index: 99;
            }

            .eventWrapper.new {
                .rbc-event {
                    color: @font-color-select;
                    background: @background-color-default;
                    border: 1px solid @border-color-select;
                    box-shadow: @box-shadow-default;
                }

                .rbc-addons-dnd-resize-ns-anchor {
                    cursor: ns-resize;
                    z-index: 99;
                    .rbc-addons-dnd-resize-ew-icon,
                    .rbc-addons-dnd-resize-ns-icon {
                        display: block !important;
                        width: 100% !important;
                        height: 5px;
                        border-top: transparent;
                    }
                }
                .rbc-addons-dnd-resize-ns-anchor:first-child {
                    top: -3px;
                }
                .rbc-addons-dnd-resize-ns-anchor:last-child {
                    bottom: -3px;
                }
            }

            .eventWrapper {
                .newbie-click-animation {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    pointer-events: none;
                }
                .newbie-swipe-animation {
                    position: absolute;
                    top: 24px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    pointer-events: none;
                    visibility: hidden;
                }
            }
            .eventWrapper.newbie {
                .rbc-event {
                    background: rgba(230, 246, 255, 0.4);
                    border: 1px solid rgba(0, 110, 225, 0.4);
                }
            }
            .eventWrapper.new:last-of-type {
                .newbie-swipe-animation {
                    visibility: visible;
                }
            }

            .eventWrapper.myEvent {
                .rbc-event {
                    color: @font-color-select;
                    background: @background-color-default;
                    border: 1px solid @background-color-default;
                }
            }

            .eventWrapper.approval-pending-event {
                .rbc-event {
                    color: #fe8c00;
                    background: #fff8e6;
                    border: 1px solid #fff8e6;
                }
            }
            .rbc-header {
                width: 139px;
                padding: 0;
                border-bottom: none;
            }
            .rbc-time-view-resources .rbc-time-header {
                display: inline-flex;
                position: sticky;
                top: 0;
                margin-right: 0px !important;
                overflow: visible !important;
                border-top: none;
                border-right: none;
                border-bottom: none;
                z-index: 99;
                .rbc-header {
                    background-color: #fff;
                    max-width: 100%;
                    overflow: visible !important;
                }
            }
            .rbc-time-header > div {
                border-top: 1px solid @border-color-default;
                border-bottom: 1px solid @border-color-default;
            }
            .rbc-time-header.rbc-overflowing {
                width: fit-content;
                min-width: 100%;
                border-top: 1px solid @border-color-default;
                border-right: none;
                border-bottom: 1px solid @border-color-default;
                .rbc-label.rbc-time-header-gutter {
                    position: sticky;
                    left: 0;
                    margin-right: 0 !important;
                    margin-left: 0px !important;
                    padding: 0;
                }
            }
            .rbc-time-header {
                .rbc-time-header-gutter {
                    padding: 0;
                }
            }
            .rbc-time-header-content > .rbc-row.rbc-row-resource {
                border-bottom: none;
            }

            .rbc-time-view .rbc-allday-cell {
                display: none !important;
            }
            .rbc-time-content {
                display: inline-flex;
                height: auto;
                padding-bottom: 3px;
                overflow: visible;
                border-top: none;
                width: fit-content;
                min-width: 100%;
            }
            .rbc-day-slot .rbc-time-slot {
                border-top: none;
            }
            .rbc-day-slot .rbc-events-container {
                margin-right: 0px;
                left: 3px;
                right: 3px;
            }
            .rbc-time-content > * + * > * {
                border-left: none;
                border-right: 1px solid @border-color-default;
            }
            .rbc-time-view-resources .rbc-time-gutter,
            .rbc-time-view-resources .rbc-time-header-gutter {
                border-right: none;
                margin-right: 0px;
                .rbc-timeslot-group {
                    border-bottom: none;
                    position: relative;
                    // 移动左侧时间位置
                    .rbc-time-slot {
                        width: 100%;
                        min-width: 56px;
                        text-align: right;
                        .rbc-label {
                            position: unset;
                            padding: 0 8px;
                            width: 100%;
                            display: block;
                            text-align: center;
                            margin-top: -10px;
                            color: rgba(0, 0, 0, 0.6);
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .rbc-time-view-resources
                .rbc-time-gutter
                .rbc-timeslot-group:first-of-type
                .rbc-time-slot
                .rbc-label {
                margin-top: 0;
            }
        }

        .week-calender, .month-calender {
            .rbc-time-gutter, .rbc-time-header-gutter {
                width: 56px;
            }
            .eventWrapper {
                .rbc-event {
                    > div {
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                }
            }
            .eventWrapper.more {
                .rbc-event {
                    background-color: unset;
                    color: #00000061;
                    > div {
                        display: flex;
                        align-items: center;
                        // padding: 0 4px;
                        &:hover {
                            color: @blue-5;
                        }
                        &:active {
                            color: @blue-7;
                        }
                    }
                }
                // .ant-popover-open {
                //     .rbc-event {
                //         > div {
                //             color: @blue-7;
                //         }
                //     }
                // }
            }
        }

        .week-calender {
            .rbc-time-view {
                overflow-y: hidden;
                .rbc-timeslot-group {
                    min-height: unset;
                }
            }
        }
        .month-calender {
            .rbc-time-view {
                .rbc-timeslot-group {
                    height: 112px;
                    min-height: 112px;
                }
            }
        }
    }
}
