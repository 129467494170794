@import '~@yeastar/pc-web-ui/src/style/variables.less';

.wx-login {
    &-container {
        position: relative;
        text-align: center;
    }
    &-tip {
        margin-top: @margin-sm;
        color: @gray-6;
    }

}
