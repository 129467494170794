.time-zone-header {
    position: relative;
    height: 100%;

    &__utc {
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 100%;

        &-wrapper {
            display: flex;
            overflow-x: hidden;
        }

        &-name {
            overflow: hidden;
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
        }
    }
}
