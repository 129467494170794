.third-part-sign {
    display: flex;
    align-items: center;
    color: rgba(0,0,0,0.38);
    font-size: 12px;
    padding-right: 12px;
    width: 80%;
    height: 32px;
    .third-part-text{
        display: -webkit-box;
        line-height: 16px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-wrap:break-word;
        word-break:break-all;
    }
}


.third-part-img {
    margin-right: 4px;
    border: 1px solid rgba(0,0,0,0.13);
    border-radius: 50%;
}
