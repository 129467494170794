@import '~@yeastar/pc-web-ui/src/style/variables.less';
.visit-status-tag.visit-status-tag {
    border: none;
    &-invited {
        color: @orange-base;
        background-color: @yellow-1;
    }
    &-arrived {
        color: @green-base;
        background-color: @green-1;
    }
    &-lated {
        color: @red-base;
        background-color: @red-1;
    }
    &-absent {
        color: @gray-7;
        background-color: @gray-1;
    }
}

.visit-type-tag.visit-type-tag {
    border: none;
    &-individual {
        color: @blue-base;
        background-color: @blue-1;
    }
    &-group {
        color: @blue-base;
        background-color: @blue-1;
    }
}