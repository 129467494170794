@import '~@yeastar/pc-web-ui/src/style/variables.less';


.HelpCenter {
    display: flex;
    align-items: center;

    & &-icon {
        width: 32px;
        height: 32px;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: 50%;


        &:hover {
            // 覆盖全局
            background-color: rgba(255, 255, 255, 0.07);
        }
        &:active {
            background-color: rgba(255, 255, 255, 0.13);
        }
    }
}
