.personal-dropdown-header {
    padding: 24px 24px 16px 24px;
    background: #E6F6FF;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .user-name {
        width: 100%;
        text-align: center;
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
    }
}

.ww-open-data-wrapper {
    min-height: 34px;
    padding: 5px 8px;
    color: rgba(0, 0, 0, 0.25);
    background-color: #F7F7F7;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    cursor: not-allowed;
    opacity: 1;
}
