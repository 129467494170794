@import '~@yeastar/pc-web-ui/src/style/variables.less';
@prefix: ~'override-modal-confirm';
@ant-prefix: ~'ant-modal-confirm';

.@{prefix} {
    &-delete {
        .@{ant-prefix}-btns .ant-btn + .ant-btn {
            background-color: @red-base;
            border-color: @red-base;
        }
    }
    .@{ant-prefix}-body {
        .@{prefix}-icon {
            font-size: 24px !important;
            &-delete.anticon {
                color: @red-base;
            }
            &-info.anticon {
                color: @orange-base;
            }
        }
    }
}
