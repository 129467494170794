@import '~@yeastar/pc-web-ui/src/style/variables.less';

.invite-detail-content-wrapper {
    padding: 24px 24px 0 24px;
    max-height: 600px;
    overflow: auto;
    &-subject {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 18px;
        word-break: normal;
    }
    div.ant-typography {
        margin-bottom: 12px;
        font-size: @font-size-xs;
        &.first {
            margin-bottom: 24px;
            // font-size: @font-size-base;
        }
        &.second {
            margin-bottom: 0;
            font-size: @font-size-base;
        }
        &.third {
            margin-bottom: 24px;
            // font-size: @font-size-base;
        }
        &.fourth {
            margin-bottom: 12px;
            // font-size: @font-size-base;
        }
        &.visitor-info-list {
            .ant-typography {
                border: 1px solid @gray-3;
                border-radius: 8px;
                padding: 12px;
                font-size: @font-size-xs;
                line-height: 16px;
                & + .ant-typography {
                    margin-top: 4px;
                }
                .ant-row {
                    & + .ant-row {
                        margin-top: 8px;
                    }
                }
                .name {
                    font-size: @font-size-base;
                    font-weight: 600;
                    line-height: 20px;
                }
                .label {
                    color: @gray-5;
                }
                .info {
                    text-align: right;
                }
            }
        }

        .visit-label {
            // font-size: @font-size-base;
            color: @gray-5;
        }

        &:last-of-type {
            padding-bottom: 8px;
        }
        .ant-tag {
            margin-right: 4px;
            margin-top: 4px;
        }
        .visitor_name {
            font-size: @font-size-base;
            font-weight: 600;
        }
    }
    .divider {
        color: @gray-2;
        background-color: @gray-2;
        border-color: @gray-2;
    }
    .c-conference {
        padding-top: 4px;
    }
}
.ant-message {
    z-index: 100000 !important;
}
