/* 复写ant  全局样式  临时存放 */
.meeting-form-wrapper
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.meeting-form-wrapper {
    .align-center-checkbox {
        line-height: 22px;
    }
    .ant-form-item-explain, .ant-form-item-extra{
        min-height:auto;
    }
}

.meeting-form__check-in-statistics {
    margin-bottom: 0 !important;

    .ant-form-item-control-input {
        min-height: 22px;
        margin: 4px 0;
        line-height: 22px;
    }
}
.meet-edit-popover {
    .meeting-form-wrapper {
        .inline-Clock {
            .ant-form-item-label > label {
                margin-top: 0;
            }
        }
    }
}
.recommend-entry{
    display: flex;
    justify-content: space-between;
    .recommend-entry__right{
        padding-right: 4px;
        color: #006EE1;
        cursor: pointer;
    }
}

.time-error-note {
    margin-top: 3px;

    &-icon {
        color: #FE8C00;
        vertical-align: top;
        position: relative;
        top: 1.5px;
    }

    &-text {
        color: #00000061;
        font-size: 12px;
        margin-left: 4px;
        display: inline-block;
        vertical-align: top;
        max-width: calc(100% - 29px);
        position: relative;
        top: 1px;
    }
}
