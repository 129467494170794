@b:yeastar-radio-card;
.@{b} {
    >.ant-radio-group {
        width: 100%;
    }
    &__cardItem {
        position: relative;
        padding: 16px 0 16px 0;
        cursor: pointer;
        // overflow-x: hidden;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 48px;
            right: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.07);
        }
        &:last-child::after {
            border-top: none;
        }
        >.ant-row {
            margin: 0 16px;
        }
        .@{b}__radioBox {

        }
        .@{b}__cardContent {
            margin-left: 8px;
            .@{b}__avatar {
                >img {
                    width: 144px;
                    height: 96px;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }
            .@{b}__info {
                margin-left: 12px;
                padding-right: 16px;
                .@{b}__title {
                    margin-bottom: 6px;
                    font-weight: 700;
                    font-size: 18px;
                    color: #000000de;
                    text-align: left;
                    line-height: 26px;
                    .ant-typography-ellipsis {
                        max-width: none !important;
                        width: 100%;
                    }
                }
                .@{b}__description {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.6);
                    text-align: left;
                    line-height: 20px;
                    .ant-typography-ellipsis {
                        max-width: none !important;
                        width: 100%;
                    }
                }
            }
        }
        .@{b}__extra {
            margin-top: 12px;
            margin-left: 48px;
            margin-right: 16px;
            line-height: 16px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
        .@{b}__line {
            margin: 0 0 15px 32px;
            position: absolute;
            bottom: 0;
            left: 32px;
            right: 0;
            border-color: rgba(0, 0, 0, 0.07);
        }
    }
    &__active {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 3px 3px 0 0;
        &::after {
            border-top: none;
        }
    }
}
