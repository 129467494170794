
@import '~@yeastar/pc-web-ui/src/style/variables.less';

.conflict{
    max-width: 380px;
    font-size: 12px;
    color: @gray-5;
    .operate{
        color: @blue-base;
        cursor: pointer
    }
    .warningIcon{
        color: @orange-base;
        font-size: @font-size-base;
    }
}
