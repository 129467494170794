@import '~@yeastar/pc-web-ui/src/style/variables.less';

.calendar-detail-content-wrapper {
    padding: 24px 24px 0 24px;
    overflow: hidden;
    &-subject {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 18px;
        word-break: normal;
    }
    .calendar-detail-conference-date {
        .conference-date-building {
            margin-top: 4px;
        }
    }
    div.ant-typography {
        margin-bottom: 16px;
        font-size: @font-size-xs;
        &.first {
            margin-bottom: 24px;
            font-size: @font-size-base;
        }
        &:last-of-type {
            padding-bottom: 8px;
        }
    }
    .visitor-icon-wrapper {
        padding-top: 8px;
        padding-right: 16px;
        font-size: 0;
    }
    .visitor-list-wrapper {
        .user-tag {
            margin-top: 4px;
            margin-left: 4px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .visitor-item {
        margin-bottom: 4px;
        padding: 8px;
        background: rgba(0,0,0,0.04);
        border-radius: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .visitor-item-name {
        flex: 1;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 12px;
    }
}
