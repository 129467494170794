@import '~@yeastar/pc-web-ui/src/style/variables.less';

.SelectPeople {
    .department-box {
        position: relative;

        .department-show-1 {
            position: absolute;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            visibility: hidden;
            .ant-form-item-control,
            .ant-form-item-control-input,
            .ant-form-item-control-input-content {
                height: 100%;
            }
            .ant-select.ant-tree-select {
                height: 100%;
                .ant-select-selector {
                    height: 100%;
                }
            }
        }

        .department-show-2 {
            position: relative;
            // bottom: -14px;
            z-index: 9;
            width: 100%;
            margin-bottom: 0;
            background-color: #fff;

            .ant-select-selector {
                // padding-right:52px ;
                padding: 0px 52px 0 5px;
                .ant-typography.ant-typography-ellipsis {
                    max-width: 110px !important;
                }
            }
            .ant-select-arrow {
                width: 52px;
                height: 100%;
                margin-top: 0;
                right: 0px;
                top: 0;
                cursor: pointer;
                div {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .ant-select-multiple .ant-select-selection-item-remove {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                margin-top: 4px;
                margin-right: 2px;
                .anticon.anticon-close {
                    width: 12px;
                    line-height: 0;
                }
            }
            .ant-select-multiple .ant-select-selection-item-remove:hover {
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 50%;
            }
        }
        .department-dropdown-no-show {
            display: none !important;
        }
        .ant-select-tree .ant-select-tree-node-content-wrapper {
            padding-left: 8px;
            overflow: hidden;
        }
        .ant-select-tree-switcher {
            width: 16px;
        }

        .ant-select-tree-switcher.ant-select-tree-switcher-noop {
            width: 0;
        }
        .ant-select-tree-checkbox {
            margin: 12px 0;
        }
        .ant-select-tree-title {
            max-width: 100%;
            height: 40px;
            line-height: 40px;

            .ant-avatar {
                width: 24px !important;
                height: 24px !important;
                line-height: 24px !important;
                .ant-avatar-string {
                    // font-weight: 100;
                    line-height: 24px !important;
                }
            }
            > span {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
        .ant-select-multiple .ant-select-selection-search {
            margin-top: 1px;
        }
        .default-name-show {
            position: relative;
            display: inline-block;
            width: 100%;
            padding-right: 25px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .user-number-show {
                position: absolute;
                right: 16px;
                color: rgba(0, 0, 0, 0.38);
                font-size: 12px;
            }
        }
        .ant-select-arrow {
            z-index: 10;
        }
    }

    .ant-select-multiple .ant-select-selection-item {
        height: 24px;
        min-height: 24px;
        background: none !important;
        border: none !important;
        padding-inline-start: 2px;
        margin: 0.5px 0 0;
    }

    .ant-select-selection-overflow-item {
        .no-first {
            display: none !important;
        }
    }

    .ant-select-selection-overflow {
        // flex-wrap: nowrap;
        height: 100%;
        min-height: 30px;
        max-height: 100px;
        padding: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        border-right: 1px solid rgba(0, 0, 0, 0.13);
        .ant-select-selection-overflow-item {
            max-width: 153px;
            height: 24px;
            margin: 3px 3px 3px 3px;
            background: rgba(0, 0, 0, 0.04);
            border-radius: 12px;
            .ant-avatar-string {
                // font-weight: 100;
            }
        }
        .ant-select-multiple .ant-select-selector::after {
            line-height: 24px;
        }
        .ant-select-multiple .ant-select-selection-item-remove {
            color: rgba(0, 0, 0, 0.07);
        }
    }
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        background: none;
    }
    .dropdown-box {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        min-height: 240px;
        .spin-box,
        .ant-select-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-height: 240px;
        }

        .ant-select-tree-treenode {
            position: relative;
            padding-left: 38px;
            .ant-select-tree-checkbox {
                position: absolute;
                left: 16px;
            }
        }
    }
}
