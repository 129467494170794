@import '~@yeastar/pc-web-ui/src/style/variables.less';

.custom-icon-btn {
    padding:@padding-xss;
    color:@gray-base;
    border-radius: 50%;
    cursor: pointer;
    transition:all 0.3s;
    &:hover {
        background-color: @gray-2;
    }
    &--disabled {
        color: @gray-4 !important;
        cursor: not-allowed !important;
    }
}
