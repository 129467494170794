.resource-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;

    .prev-btn,
    .next-btn {
        position: absolute;
        display: none;
        &--disabled {
            color: rgba(0, 0, 0, 0.38);
            cursor: not-allowed;
        }
    }

    .prev-btn {
        // left: -16px;
        left: 0px;
    }

    .next-btn {
        // right: -16px;
        right: 0px;
    }

    &-item {
        width: 100%;
        &:hover {
            .move-left-icon,
            .visibility-toggle-icon {
                opacity: 1;
            }
        }
        .move-left-icon {
            transform: rotate(-90deg);
        }
        .move-left-icon,
        .visibility-toggle-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            font-size: 20px !important;
            opacity: 0;
            transition: opacity 0.3s;
        }
        .anticon {
            color: rgba(0, 0, 0, 0.38);
            cursor: pointer;
        }
    }

    &-avatar {
        position: relative;
        .busy-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #fe8c00;
            background-color: #fff;
            border-radius: 50%;
        }
    }

    &-name {
        max-width: 100%;
        margin-top: 6px;
        margin-bottom: 0 !important;
        padding: 0 16px;
    }
}

.rbc-time-header-content:nth-child(2) .resource-header .PaginationBtn:first-child {
    display: flex;
}

.rbc-time-header-content:last-child .resource-header .PaginationBtn:last-child {
    display: flex;
}
