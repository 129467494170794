@import '~@yeastar/pc-web-ui/src/style/variables.less';
.checkin-status-tag.checkin-status-tag {
    border: none;
    &-unregistration {
        color: @orange-base;
        background-color: @yellow-1;
    }
    &-registration {
        color: @green-base;
        background-color: @green-1;
    }
}
