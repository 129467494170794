@import '~@yeastar/pc-web-ui/src/style/variables.less';

.related-conference {
    margin-bottom: 16px;
    padding: 12px 16px;
    background: rgba(0,0,0,0.04);
    border-radius: 4px;
    word-break: break-all;

    &-subject {
        flex: 1;
        padding-left: 4px;
        font-weight: 600;
        font-size: 14px;
    }
    &-view-btn {
        padding-left: 8px;
    }
    &-rest-info {
        margin-top: 8px;
        font-size: 12px;
    }
}
