.tree-select-modal {
    .ant-modal-body {
        padding: 24px 24px 16px 24px !important;
    }
    .tree-select-modal-content {
        display: flex;
        width: 100%;
        // height: 518px;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        .content-title {
            padding-right: 16px;
            line-height: 32px;
        }
        .ant-input-affix-wrapper .ant-input-prefix {
            color: rgba(0, 0, 0, 0.24) !important;
        }
        .ant-input-affix-wrapper .ant-input-suffix {
            color: rgba(0, 0, 0, 0.38) !important;
            .ant-input-clear-icon:hover {
                color: rgba(0, 0, 0, 0.38) !important;
            }
        }
        .content-right {
            padding: 16px 0px 0 16px;
        }
        .content-left {
            padding: 16px 0px 0 8px;
        }
        .content-left-scroll {
            padding-top: 16px;
            // padding-left:24px ;
            .left-scroll {
                height: 100%;
                // overflow: scroll;
                padding-right: 12px;
            }
            .ant-tree,
            .ant-tree-list,
            .ant-tree-list-holder {
                height: 100%;
            }
            .ant-tree-list-holder {
                // height: 447px !important;
                // max-height: 447px !important;
                overflow-x: auto !important;
                overflow-y: hidden !important;
            }
            .ant-tree-list-holder > div {
                overflow: visible !important;
            }
            .ant-tree-list-holder-inner {
                position: relative;
                // height: 447px;
                // max-height: 447px;
            }
            .ant-tree .ant-tree-treenode {
                min-height: 40px;
                padding-bottom: 0;
                padding-left: 24px;
                line-height: 40px;
                align-items: center;
                min-width: 100%;
                .ant-tree-switcher {
                    width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                }
                .ant-tree-switcher.ant-tree-switcher-noop {
                    width: 4px;
                    z-index: 1;
                }
            }
            .ant-tree .ant-tree-treenode:hover {
                background-color: #f5f5f5 !important;
            }
            .ant-tree .ant-tree-node-content-wrapper {
                display: flex;
                align-items: center;
                height: 40px;
                min-width: 180px;

                &::after {
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: -100%;
                    z-index: 1;
                }
            }
            .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
                background-color: transparent !important;
            }
            .ant-tree .ant-tree-node-content-wrapper:hover {
                background-color: transparent !important;
            }
            .ant-tree-checkbox {
                position: absolute;
                margin-left: -18px;
                margin-top: -1px;
                z-index: 2;
            }
        }
        .content-right-scroll,
        .content-left-scroll {
            position: relative;
            height: calc(100% - 34px);
        }
        .content-right-scroll {
            // overflow-x: hidden;
            // overflow-y:scroll ;
            .user-list {
                position: relative;
                line-height: 40px;
                .close-box {
                    position: absolute;
                    top: 0;
                    right: 12px;
                    z-index: 1;
                    color: rgba(0, 0, 0, 0.38);
                    .anticon {
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
        .content-left {
            width: 310px;
            border-right: 1px solid rgba(0, 0, 0, 0.07);
        }
        .content-right {
            width: 240px;
        }
        .spin-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }
}
