.so-meeting-service {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    // width: 408px;
    min-height: 32px;

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 6px 8px;

        .anticon-spin {
            vertical-align: 3px;
        }
    }

    &-checked > &-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }

    &-content {
        padding-left: 16px;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 16px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        &:last-child {
            border-bottom: 0;
        }

        .ant-checkbox-wrapper {
            flex: 1;
            display: flex;
            align-items: center;

            .ant-checkbox {
                top: 0;

                & + span {
                    flex: 1;
                    display: block;
                    padding-left: 16px;
                    font-size: 12px;
                    line-height: 16px;
                    color: rgba(0, 0, 0, 0.6);

                    > .title {
                        display: flex;
                        color: rgba(0, 0, 0, 0.87);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        margin-bottom: 2px;

                        >.LongTextDiv {
                            flex: 1;
                            width: 0;
                        }
                    }

                    .detail {
                        display: flex;
                        user-select: none;

                        >.LongTextDiv {
                            flex: 1;
                            width: 0;
                        }
                    }
                }
            }
        }

        & &__edit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            color: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            &:active {
                opacity: .8;
            }
        }
    }

    &-error-tip {
        padding-right: 16px;
        font-size: 12px;
        line-height: 16px;
        color: #F63B3B;
        margin: 4px 0;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    &-form .contact-item {
        .ant-select-multiple {
            .ant-select-selection-item {
                height: 24px;
                min-height: 24px;
                padding-inline-start: 2px;
                // margin-top: 1px;
                border: 0;
                border-radius: 12px;
            }
        }

        .ant-select-selection-item-content {
            margin-right: 0px;

            .user-tag {
                padding: 0px;
                padding-left: 4px;
                background-color: transparent;
            }
        }

        .ant-select-selection-item-remove {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin-top: 4px;
            margin-right: 2px;
        }
    }
}

.so-ms-form-dropdown-box {
    .ant-select-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 240px;
    }

    .ant-select-tree-treenode {
        position: relative;
        padding-left: 16px;

        .ant-select-tree-checkbox {
            position: absolute;
            left: 16px;
        }
    }

    .ant-select-tree-title {
        line-height: 32px;

        .user-tag {
            padding: 4px 8px;
        }
    }
}
