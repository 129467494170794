.participant-list {
    &-wrapper {
        max-height: 152px;
        margin-top: 8px;
        padding: 9px 0;
        overflow: auto;
        background: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
    }
    &-item {
        padding: 3px 16px 3px 8px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            .participant-list-item--operation .anticon {
                display: inline-block;
            }
        }
        &--user {
            display: flex;
            flex: 1;
            align-items: center;
        }
        &--operation {
            display: flex;
            flex: none;
            align-items: center;
            .anticon {
                padding: 0 5px;
                color: rgba(0, 0, 0, 0.38);
                font-size: 16px;
                cursor: pointer;
                &:last-child {
                    padding-right: 0;
                }
            }
            .anticon:not(.remove) {
                display: none;
            }
        }
    }
}
