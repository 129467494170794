.calendar-detail-content-wrapper .ant-form-item .ant-select {
    width: 100%;
    max-width: 100%;
}

.add-device-modal .ant-modal-body {
    max-height: calc(80vh);
    overflow-y: auto;
}

.add-device-modal {
    .ant-space-align-center {
        justify-content: space-around;
        display: flex;
    }
}
.ant-form-item.i-check-box {
    margin-top: -14px;
}
