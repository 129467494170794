@import '~@yeastar/pc-web-ui/src/style/variables.less';
@namespace: ~'wp';

.@{namespace}-attendance-statistic {
    display: flex;
    flex-direction: column;

    &__checked {
        height: 16px;
        line-height: 16px;
        margin-bottom: 8px;
    }

    &__export {
        display: inline-block;

        span {
            padding-right: 3px;
        }
    }

    &__button {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        margin-bottom: 8px;
        color: @blue-base;
        cursor: pointer;
    }

    &__qr-code {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px 0 8px;

        .download-icon {
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }
    }
 }
