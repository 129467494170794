@header-height: 64px;
@footer-height: 65px;

.meeting-conflict {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100% - 540px);
    height: 100%;
    &--invisible {
        display: none;
    }
    &-date-select-header {
        padding: 16px 22px 16px 36px;
        /** 日程header的上边框与左侧对齐 **/
        padding-bottom: 14px;
    }
    &__btn-wrapper > span {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #e5e5e5;
        &:first-child {
            border-right-width: 0;
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: @footer-height;
        padding: 13px 28px;
        border: 1px solid #f2f2f2;
        border-left: none;
        border-right: none;
        &__desc {
            font-size: 12px;
            display: block;
            &--available {
                color: #20c161;
            }
            &--busy {
                color: #fe8c00;
            }
            &--hidden {
                display: none;
            }
        }
    }

    &-participant-wrapper {
        height: calc(100% - @header-height - @footer-height);
        padding-right: 10px;
        padding-bottom: 0;

        .ys-calendar-meetings .ys-calendar-container .ys-calendar .rbc-time-view {
            overflow-x: auto;
            padding-right: 1px;
        }

        .ys-calendar-meetings {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }

        /** 覆盖日程组件header样式 **/
        .rbc-time-view-resources .rbc-time-gutter {
            padding-left: 3px;
        }
    }
    .ys-calendar-meetings .ys-calendar-container .ys-calendar .rbc-day-slot .rbc-events-container {
        left: 0;
        right: 0;
        .rbc-event-wrapper {
            position: absolute;
            min-height: 1em;

            .rbc-event {
                position: static !important;
                width: 100% !important;
                height: 100% !important;
                color: rgba(0, 0, 0, 0.24);
            }

            .rbc-addons-dnd-resize-ns-anchor {
                cursor: ns-resize;
                z-index: 99;
                .rbc-addons-dnd-resize-ew-icon,
                .rbc-addons-dnd-resize-ns-icon {
                    display: block !important;
                    width: 100% !important;
                    height: 5px;
                    border-top: transparent;
                }
            }

            .rbc-addons-dnd-resize-ns-anchor:first-child {
                top: -3px;
            }

            .rbc-addons-dnd-resize-ns-anchor:last-child {
                bottom: -3px;
            }

            &--edited {
                color: #0054ba;
                background-color: #e6f6ff;
            }
            &--new {
                background-color: transparent;
                .rbc-event {
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
                }
            }
            &--new.rbc-event-wrapper--available {
                .rbc-event {
                    color: #20c161;
                    background-color: rgba(240, 255, 243, 0.5);
                    border-color: #44cf77;
                }
            }
            &--new.rbc-event-wrapper--busy {
                .rbc-event {
                    color: #fe8c00;
                    background-color: rgba(255, 248, 230, 0.5);
                    border-color: #fe8c00;
                }
            }
            &--viewed {
                background-color: #eee;
                color: rgba(0, 0, 0, 0.6);
                .rbc-event {
                    cursor: auto;
                }
            }
            &--disabled {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.04);
                color: rgba(0, 0, 0, 0.6);
                left: 0;
            }
            &--expired {
                background-color: rgba(0, 0, 0, 0.04);
                left: 0;
            }
        }
    }
}
