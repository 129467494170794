@import '~@yeastar/pc-web-ui/src/style/variables.less';

.override-ant-upload-circle {
    .ant-upload.ant-upload-select-picture-card {
        overflow: hidden;
        border-radius: 50%;
    }
}
.upload-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: left;
    .upload-inner-mask {
        position: absolute;
        top: 50%;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content:center;
        width: 100%;
        color: #fff;
        font-size: @font-size-xs;
        background-color: @gray-7;
        transition: top .3s;
    }
}
