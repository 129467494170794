@import '~@yeastar/pc-web-ui/src/style/variables.less';

.override-ant-popover {
    width: 400px;
    padding-top: 8px;
    & &-infinite-scroll-wrapper {
        height: 480px;
        padding: 0 24px;
    }
    & .ant-popover-inner-content {
        padding: 18px 0 12px;
    }
}
.notification-wrapper {
    display: flex;
    align-items: center;
    & &-badge {
        color: #fff;
    }
    & &-icon {
        width: 32px;
        height: 32px;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: 50%;
        &:hover {
            // 覆盖全局
            background-color: rgba(255, 255, 255, 0.07);
        }
        &:active {
            background-color: rgba(255, 255, 255, 0.13);
        }
    }
}
.notification_result_tip {
    margin-top: 12px;
    font-family: SFProText-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #0000003d;
    text-align: left;
    line-height: 16px;
}
.notification__header {
    padding: 0 33px 0 24px;
    .notification-name {
        line-height: 26px;
        font-size: 18px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
        text-align: left;
    }
    .notification-read-all {
        line-height: 18px;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        >.ant-btn {
            padding-right: 0;
            height: 18px;
        }
    }
}
.notification__oeprateBtn {
    margin-top: 4px;
    padding: 2px 16px 2px 0px !important;
    min-width: auto !important;
    text-align: left !important;
}
