@import '~@yeastar/pc-web-ui/src/style/variables.less';

.reload-qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__img {
        margin: 24px 0;

        img {
            width: 120px;
            height: 120px;
        }
    }

    &__prompt {
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
        text-align: center;
    }

    &__btn {
        margin: 24px 0 36px;
    }
}
