.ant-select-dropdown .ant-select-item.ant-select-item-group {
    color: rgba(0, 0, 0, 0.38);
    font-weight: 400;
    font-size: 12px;

    &:nth-child(n + 3) {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
    }
}

.ant-form-item-label > label .ant-form-item-tooltip {
    cursor: pointer !important;
}
