@import '~@yeastar/pc-web-ui/src/style/variables.less';

.maintenance-alert {
    display: flex;
    flex: none !important;
    justify-content: center;
    padding: 1px 12px !important;
    font-size: 12px;
    :global(.ant-alert-message) {
        font-size: 12px;
        color: #662b00;
        line-height: 20px;
    }
    :global(.ant-alert-content) {
        flex: none;
    }
    :global(.ant-alert-icon) {
        font-size: 12px;
    }
}
.maintenance-notification {
    :global(.ant-notification-notice-message) {
        font-weight: bold;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        text-align: left;
        line-height: 24px;
        margin-left: 36px;
    }
    :global(.ant-notification-notice-description) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        text-align: left;
        line-height: 20px;
        margin-left: 36px;
    }
}
