@import '~@yeastar/pc-web-ui/src/style/variables.less';

.user-tag-pre {
    // padding: 0px 8px;
    font-size: @font-size-xs;
    line-height: 20px;

    img {
        margin-right: 4px;
        border-radius: 50%;
    }
}

.user-tag-pre.has-bg {
    background-color: @gray-1;

}
