@import '~@yeastar/pc-web-ui/src/style/variables.less';

@enterprise-item-logo-height: 30px;

.enterprise-item {
    margin-bottom: @margin-xs;
    padding: @padding-md @padding-lg;
    font-size: @font-size-sm;
    border: 1px solid @gray-3;
    border-radius: @border-radius-xs;
    cursor: pointer;
    transition: background-color, border-color 0.3s;
    &-active {
        background-color: @blue-1;
        border-color: @blue-6 ;
        & .icon-gou {
            display: inline-block;
            color: @blue-6;
        }
        & .icon-right-narrow {
            display: none;
        }
    }
    & .icon-right-narrow {
        display: inline-block;

    }
    & .icon-gou {
        display: none;
    }
    &:hover {
        background-color: @background-color-hover;
    }
    &-logo {
        width:@enterprise-item-logo-height;
        height: @enterprise-item-logo-height;
    }
}
