@import '~@yeastar/pc-web-ui/src/style/variables.less';

.meet-edit-popover {
    /** 覆盖popover组件生成的style width：400px 的宽度样式 */
    width: 540px;
    padding: 0;

    .ant-modal-body {
        padding: 0;
    }

    .confirmDiv {
        .ant-modal-body {
            padding: 16px 24px;
        }
    }
    .selected-recent-tip {
        padding-left: 16px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 36px;
    }
    .meeting-form-item-wrapper {
        padding-top: 16px;
        .meeting-link-form-item--hidden {
            display: none;
        }
        .meeting-link-form-item .ant-form-item-control-input {
            min-height: 20px;
        }
    }
    .meet-edit-popover-title {
        display: flex;
        justify-content: space-between;
        h5.ant-typography {
            margin-bottom: 0;
        }

        .meet-edit-popover-title-text {
            color: rgba(0, 0, 0, 0.87);
            font-size: 18px;
            line-height: 24px;
        }
    }

    .ant-popover-inner {
        border-radius: 8px;
    }

    .ant-popover-inner-content {
        padding: 0;
        .meeting-modal-container {
            display: block;
        }
    }

    .meet-edit-popover-content {
        padding: 24px 24px 0 24px;
    }

    .ant-popover-arrow {
        display: none !important;
    }

    .meet-edit-popover-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        .ant-btn {
            min-width: 0;
        }
    }
    .meet_button_animation {
        position: absolute;
        top: 50%;
        left: calc(100% - 10px);
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    .popover-footer {
        padding: 8px 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        line-height: 32px;
        .ant-btn {
            min-width: 0;
        }
    }
    .inline-clock-line {
        margin-bottom: 16px;
    }
    .inline-Clock {
        .inline-clock-item-l {
            display: inline-block;
            width: 116px;
        }

        .inline-clock-item-s {
            display: inline-block;
            width: 92px;
            .select-hide {
                display: none;
            }
        }

        .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: -14px;
        }
        .schedule-setting-timezone {
            font-weight: 400;
            font-size: 12px;
            color: #00000099;
            line-height: 16px;
            padding: 4px 0;
        }
        .ant-form-item-label > label {
            margin-top: -4px;
        }
    }

    .line-Space {
        width: 100%;

        .ant-space {
            width: 100%;

            .ant-space-item {
                width: calc(50% - 4px);
            }
        }
    }

    .ant-form-item .ant-select,
    .ant-form-item .ant-cascader-picker {
        /* 弥补部分item长度不一的情况 */
        // max-width: 420px;
    }
    .inline-Clock {
        .ant-form-item .ant-select,
        .ant-form-item .ant-cascader-picker {
            // max-width: 190px;
        }
    }
    // 会议服务form独有
    .meeting-service-form {
        .ant-select-multiple {
            .ant-select-selection-item {
                height: 22px;
                min-height: 24px;
                background: #f7f7f7 !important;
                border: 1px solid #f0f0f0 !important;
                padding-inline-start: 8px;
            }
        }
        .contactForm {
            .ant-select-multiple {
                .ant-select-selection-item {
                    height: 24px;
                    min-height: 24px;
                    margin: 0px;
                    background: none !important;
                    border: none !important;
                    padding-inline-start: 2px;
                    margin-top: 0.5px;
                }
            }
            .ant-select-selection-item-content {
                margin-right: 0px;
                .user-tag {
                    padding: 0px;
                    padding-left: 4px;
                    background-color: rgba(0, 0, 0, 0.01) !important;
                }
            }
            .ant-select-selection-item-remove {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                margin-top: 4px;
                margin-right: 2px;
            }
        }
    }
    .ant-select-multiple {
        .ant-select-selection-item {
            height: 24px;
            min-height: 24px;
            margin: 0px;
            background: none !important;
            border: none !important;
            padding-inline-start: 2px;
            margin-top: 0.5px;
        }
    }
    .multiple-notification-time{
        .ant-select-selection-item{
            background: #f7f7f7 !important;
            border: 1px solid #f0f0f0 !important;
            margin-inline-end: 5px;
            padding-inline-start: 8px;
            padding-inline-end: 4px;
        }
    }
    .department-box {
        position: relative;

        .department-show-1 {
            position: absolute;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            visibility: hidden;
            .ant-form-item-control,
            .ant-form-item-control-input,
            .ant-form-item-control-input-content {
                height: 100%;
            }
            .ant-select.ant-tree-select {
                height: 100%;
                .ant-select-selector {
                    height: 100%;
                }
            }
        }

        .department-show-2 {
            position: relative;
            // bottom: -14px;
            z-index: 9;
            width: 100%;
            margin-bottom: 0;
            background-color: #fff;

            .ant-select-selector {
                // padding-right:52px ;
                padding: 0px 52px 0 5px;
                .ant-select-selection-item-content {
                    margin-right: 0px;
                }
                .ant-typography.ant-typography-ellipsis {
                    max-width: 130px !important;
                }
            }
            .ant-select-arrow {
                width: 52px;
                height: 100%;
                right: 0px;
                top: 0;
                margin-top: 0;
                cursor: pointer;
                div {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .ant-select-multiple .ant-select-selection-item-remove {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                margin-top: 3px;
                margin-right: 2px;
                .anticon.anticon-close {
                    width: 12px;
                    line-height: 0;
                }
            }
            .ant-select-multiple .ant-select-selection-item-remove:hover {
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 50%;
            }
        }
        .department-dropdown-no-show {
            display: none !important;
        }
        .ant-select-tree .ant-select-tree-node-content-wrapper {
            padding-left: 8px;
            overflow: hidden;
        }
        .ant-select-tree-switcher {
            width: 16px;
        }

        .ant-select-tree-switcher.ant-select-tree-switcher-noop {
            width: 0;
        }
        .ant-select-tree-checkbox {
            margin: 12px 0;
        }
        .ant-select-tree-title {
            max-width: 100%;
            height: 40px;
            line-height: 40px;

            .ant-avatar {
                width: 24px !important;
                height: 24px !important;
                line-height: 24px !important;
                .ant-avatar-string {
                    // font-weight: 100;
                    line-height: 24px !important;
                }
            }
            > span {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
        .ant-select-multiple .ant-select-selection-search {
            margin-top: 1px;
        }
        .default-name-show {
            position: relative;
            display: inline-block;
            width: 100%;
            padding-right: 25px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .user-number-show {
                position: absolute;
                right: 16px;
                color: rgba(0, 0, 0, 0.38);
                font-size: 12px;
            }
        }
        .ant-select-arrow {
            z-index: 10;
        }
    }

    .ant-select-selection-overflow-item {
        .no-first {
            display: none !important;
        }
    }
    .department-box {
        .ant-select-selection-overflow {
            // flex-wrap: nowrap;
            height: 100%;
            min-height: 30px;
            max-height: 100px;
            padding: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            border-right: 1px solid rgba(0, 0, 0, 0.13);
            .ant-select-selection-overflow-item {
                max-width: 180px;
                height: 24px;
                margin: 3px 3px 3px 3px;
                background: rgba(0, 0, 0, 0.04);
                border-radius: 12px;
            }
            .ant-select-selection-overflow-item-suffix{
                max-width: 100%;
            }
            .ant-select-multiple .ant-select-selector::after {
                line-height: 24px;
            }
            .ant-select-multiple .ant-select-selection-item-remove {
                color: rgba(0, 0, 0, 0.07);
            }
        }
        .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
            background: none;
        }
    }
    .GuestSelect {
        height: 100%;
        min-height: 30px;
        max-height: 100px;
        padding: 0;
        overflow-x: hidden;
        .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
                max-width: 153px;
                height: 24px;
                margin: 3px 3px 3px 3px;
                background: rgba(0, 0, 0, 0.04);
                border-radius: 12px;
            }
            .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
                background: none;
            }

            .ant-select-multiple .ant-select-selector::after {
                line-height: 24px;
            }
            .ant-select-multiple .ant-select-selection-item-remove {
                color: rgba(0, 0, 0, 0.07);
            }
            .ant-select-selection-item {
                padding-left: 4px;
            }
        }
    }
    .dropdown-box {
        min-height: 240px;
        .spin-box,
        .ant-select-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-height: 240px;
        }

        .ant-select-tree-treenode {
            position: relative;
            padding-left: 38px;
            .ant-select-tree-checkbox {
                position: absolute;
                left: 16px;
            }
        }
    }
}
// 预约弹窗展开的样式
.meet-edit-popover--unfold {
    /** 覆盖掉antd popver组件自动生成的style属性值，避免弹窗整体偏移 */
    width: auto !important;
    left: 0 !important;
    top: 56px !important;
    padding: 0 !important;
    .ant-popover-inner {
        border-radius: 0 !important;
    }
    .meet-edit-popover-content {
        height: calc(100% - 64px);
        overflow: hidden;
    }
    .ant-popover-inner-content {
        width: 100vw;
        // 56px是header的高度
        height: calc(100vh - 56px);
        overflow-x: hidden;
        .meeting-modal-container {
            display: flex;
            height: 100%;
        }
        .meeting-form-item-wrapper {
            padding-top: 24px;
        }
        .meet-edit-popover-footer {
            padding: 16px 24px;
        }
    }
    .meeting-form-wrapper {
        width: 540px;
        height: 100%;
        border-right: 1px solid @separator-color-base;
        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        .meeting-form-item-wrapper {
            // 41px 是头部 标题的高度
            height: calc(100% - 41px);
            margin-right: -24px;
            overflow: scroll;
        }
    }
    .meet-edit-popover-title {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        padding-bottom: 16px;
        &::after {
            position: absolute;
            display: inline-block;
            content: '';
            height: 1px;
            background-color: @separator-color-base;
            width: calc(100% + 48px);
            left: -24px;
            bottom: 0;
        }
        .anticon {
            flex: none;
        }
        h5.ant-typography {
            // 补齐左侧箭头占据的宽度，让标题相对窗口居中
            text-indent: -24px;
            flex: 1;
            text-align: center;
        }
    }
}
