@import '~@yeastar/pc-web-ui/src/style/variables.less';

.ant-upload.ant-upload-drag {
    border-style: dashed;
}

.ant-upload-list {
    margin-top: 4px;
    border-radius: 4px;
}

.ant-upload-list-text-container,
.ant-upload-list-picture-container {
    padding: 8px 12px;
    border-right: 1px solid @gray-3;
    border-bottom: 1px solid @gray-3;
    border-left: 1px solid @gray-3;

    &:first-child {
        border-top: 1px solid @gray-3;
        border-radius: 4px 4px 0 0;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }
}

.item-border {
    .ant-form-item-control-input {
        border: 1px solid @gray-3;
        border-bottom: 0;
        border-radius: 4px;

        .ant-radio-group,
        .ant-checkbox-group {
            width: 100%;

            .padding-box {
                padding: 8px 12px;
                border-bottom: 1px solid @gray-3;
            }
        }
    }
}

.upload-border {
    .ant-form-item-control-input-content,
    .ant-upload {
        width: 100%;
    }

    .form_item_upload {
        padding: 22px;
        text-align: center;
        border: 1px dashed @gray-3;
        border-radius: 4px;
        cursor: pointer;
    }
}

.upload-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;

    .ant-space + div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-image,
    [role='img'] {
        display: block;
    }
}

.text-hide {
    .ant-image-mask-info {
        font-size: 0;
    }

    .ant-image-mask-info .anticon {
        font-size: 12px;
        margin-inline-end: 0;
    }
}
