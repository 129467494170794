@import '~@yeastar/pc-web-ui/src/style/variables.less';

@collapse-prefix: ~'ant-collapse';

.has-border-bottom {
    border-bottom: 1px solid @separator-color-base;
}

.conference-notification-item {
    padding: @padding-sm 0;
    .has-border-bottom();

    &-header {
        font-size: @font-size-xs;
        line-height: 20px;
    }

    &-title {
        max-width: 280px !important;
        font-weight: bold;
    }

    &-expanded-detail {
        margin-top: 5px;
        color: @gray-7;
        font-size: @font-size-xs;
        line-height: 20px;

        div.ant-typography {
            margin-bottom: 0;
            color: @gray-7;
        }
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        padding: 4px 0 0 0;
    }

    .ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
        padding-left: 0;
    }

    .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
        padding: 0 0 0 2px;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 0 0 @padding-sm 2px;
    }
    .notification-body {
        white-space: pre-wrap;
        word-break: break-all;
    }
    .notification__header-time {
        color: rgba(0, 0, 0, 0.38);
    }
    .notification__check-dot {
        .ant-badge-dot {
            width: 8px;
            height: 8px;
        }
    }
}
.Chevron_Right {
    // width: 22px;
    // height: 22px;
    // line-height: 22px;
}

