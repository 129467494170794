@import '~@yeastar/pc-web-ui/src/style/variables.less';

.right-down-wrapper {
    height: 32px;
    padding: 0 4px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.13);
    border-radius: 20px;
    &:hover {
        background: rgba(255, 255, 255, 0.07);
    }
    &:active {
        background: rgba(255, 255, 255, 0.13);
    }
    .user-name {
        color: #fff;
    }
}

.layout-body {
    .global-header {
        color: #fff;
        background: @blue-base;

        &__overflow-menu {
            position: relative;
            display: inline-flex;
            align-items: center;
            color: #fff;

            span {
                margin-right: 0;
                transition: transform 0.2s ease-in-out;
            }

            .border-line {
                display: none;
                position: absolute;
                bottom: 1px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
            }
        }

        &-wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            .ant-menu {
                color: #fff;
                background-color: @blue-base;
                border: none;
                li {
                    border-bottom: none !important;

                    &.ant-menu-submenu-selected {
                        .global-header__overflow-menu {
                            .border-line {
                                display: block;
                            }
                        }
                    }
                }
            }

            .ant-menu-item a {
                color: #fff;
                &:hover {
                    color: #fff;
                }
            }

            .header-tab-wrapper {
                .ant-menu-submenu-open {
                    .global-header__overflow-menu {
                        span {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &-logo-wrapper {
            display: flex;
            align-items: center;
            margin-right: 56px;
        }

        &-title {
            padding-left: 8px;
            font-size: @font-size-sm;
            border-left: 1px solid @separator-color-dark;
        }

        &-admin-entry {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.13);
            border-radius: 16px;
            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.07);
            }
        }
    }
}
.ant-dropdown.ant-dropdown-placement-bottomRight {
    z-index: 200;
}

.override-ant-dropdown-menu {
    min-width: 240px;
    margin-top: 4px !important;
    padding-top: 0 !important;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    overflow: hidden;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        height: 40px;
        padding: 0 @padding-md;
        line-height: 40px;
    }

    .ant-dropdown-menu-item {
        position: relative;

        a {
            color: rgba(0, 0, 0, 0.87);
        }

        &:last-child {
            // margin-top: 8px;
            margin-bottom: 4px;
            height: auto;
            &::before {
                position: relative;
                margin-top: 8px;
                margin-bottom: 8px;
                // top: -8px;
                // left: 8px;
                // right: 8px;
                display: block;
                height: 1px;
                background-color: @separator-color-base;
                content: '';
            }
        }
    }

    .ant-dropdown-menu-submenu-expand-icon {
        top: 50%;
        transform: translateY(-50%);
    }
    .munu-profile-setting {
        margin-top: 8px;
    }
    .menu-logout {
        margin-top: 8px;
    }
    .personal-dropmenu-item {
        padding: 0 8px;
        &:hover {
            background: none;
        }
        >div {
            padding: 0 16px;
            border-radius: 8px;
            &:hover {
                background-color: @gray-1;
            }
        }
        .anticon {
            color: @gray-7;
        }
    }
}

.override-ant-dropdown-submenu {
    min-width: 180px;

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        height: 36px;
        padding: 0 @padding-md;
        line-height: 36px;
    }
    .personal-dropmenu-sub-item {
        padding: 0 8px;
        &:hover {
            background: none;
        }
        >div {
            padding: 0 16px;
            border-radius: 8px;
            &:hover {
                background-color: @gray-1;
            }
        }
    }
}

.fix-show-all {
    .ant-row {
        display: block;
        span {
            vertical-align: middle;
        }
    }
}


