.override-ant-layout-sider{
    .override-ant-menu {
        margin-left: -1px;
        &.ant-menu-inline-collapsed{
            width: 56px;
        }
    }
    .ant-layout-sider-trigger{
        background-color:  #FFF;
        border-right: 1px solid #F2F2F2 ;
        .sider-trigger{
            position: absolute;
            left: 20px;
        }
    }
}
