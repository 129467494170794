.input_drop_down_p_client {
    position: relative;

    .ant-select.input_drop_down_c {
        position: absolute;
        top: 26px;
        height: 32px;
        width: 100%;
        .ant-select-selection-item {
            display: none;
        }
        .ant-select-selector {
            width: 0 !important;
            // height: 0 !important;
            overflow: hidden;
            border: 0 !important;
            padding: 0;
        }

        .ant-select-arrow {
            width: 0 !important;
            // height: 0 !important;
            overflow: hidden;
        }

        .item {
            padding: 5px 0;
            position: relative;

            .ant-checkbox-wrapper {
                width: 100%;
            }

            .ant-checkbox {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .main-tit,
            .sub-tit {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .main-tit {
                padding-left: 16px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
                text-align: left;
                line-height: 20px;
            }

            .sub-tit {
                padding-left: 16px;
                margin-top: 2px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.6);
                text-align: left;
                line-height: 16px;
            }
        }
    }
}
.calendar-detail-content-wrapper .ant-form-item .ant-select {
    width: 100%;
    max-width: 100%;
}
