.people-wapper {
    display: flex;
    align-items: center;
    width: 100%;

    .people-avatar {
        display: inline-flex;
        justify-items: center;
        margin-right: 2px;
    }
    .people-name {
        max-width: 99%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
