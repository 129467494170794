.visitor-table-list {
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    .ant-table-placeholder {
        display: none;
    }
    .ant-table-thead>tr>th {
        background: #fff;
    }

    .add-btn {
        display: inline-block;
        padding: 12px 0 12px 16px;
    }

    /** 让 输入框 居中显示，修复 爆红提示语 占据的高度将 输入框顶上去，导致一行内的输入框没有对齐 **/
    .ant-table .ant-table-tbody>tr>td {
        vertical-align: text-top;
    }
}

.visitor-list-form-item-required label {
    &::before {
        display: inline-block;
        margin-right: 4px;
        color: #f63b3b;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }

}
