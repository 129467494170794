@gray-base: rgba(0, 0, 0, 0.87);
@gray-1: rgba(0, 0, 0, 0.04);
@gray-2: rgba(0, 0, 0, 0.07);
@gray-3: rgba(0, 0, 0, 0.13);
@gray-4: rgba(0, 0, 0, 0.24);
@gray-5: rgba(0, 0, 0, 0.38);
@gray-6: rgba(0, 0, 0, 0.48);
@gray-7: rgba(0, 0, 0, 0.6);
@gray-8: rgba(0, 0, 0, 0.75);
@gray-9: @gray-base;
@gray-10: rgba(0, 0, 0, 0.93);

@blue-base: #006ee1;
@blue-1: #e6f6ff;
@blue-2: #a3dcff;
@blue-3: #7ac8ff;
@blue-4: #50adfa;
@blue-5: #268ded;
@blue-6: @blue-base;
@blue-7: #0054ba;
@blue-8: #003e94;
@blue-9: #002a6e;
@blue-10: #001126;
@blue-11: #0070C0;

@green-base: #20c161;
@green-1: #f0fff3;
@green-2: #c6f5d3;
@green-3: #97e8af;
@green-4: #6bdb91;
@green-5: #44cf77;
@green-6: @green-base;
@green-7: #0d9e4c;
@green-8: #04783a;
@green-9: #005229;
@green-10: #002b17;

@yellow-base: #ffcb00;
@yellow-1: #fffee6;
@yellow-2: #fff9a3;
@yellow-3: #fff27a;
@yellow-4: #ffe852;
@yellow-5: #ffdb29;
@yellow-6: @yellow-base;
@yellow-7: #d9a600;
@yellow-8: #b38300;
@yellow-9: #8c6200;
@yellow-10: #664400;

@orange-base: #fe8c00;
@orange-1: #fff8e6;
@orange-2: #ffe2a3;
@orange-3: #ffd17a;
@orange-4: #ffbd52;
@orange-5: #ffa629;
@orange-6: @orange-base;
@orange-7: #d97000;
@orange-8: #b35600;
@orange-9: #8c3f00;
@orange-10: #662b00;

@red-base: #f63b3b;
@red-1: #fff2f0;
@red-2: #ffe4e0;
@red-3: #ffbfb8;
@red-4: #ff968f;
@red-5: #ff6b66;
@red-6: @red-base;
@red-7: #cf272d;
@red-8: #a81821;
@red-9: #820c18;
@red-10: #5c0713;

/** box-shadow UI规范 */
@box-shadow-1: 0 2px 4px 0 rgba(0,0,0,0.01), 0 3px 6px 3px rgba(0,0,0,0.01), 0 2px 6px 0 rgba(0,0,0,0.03);
@box-shadow-2: 0 -2px 4px 0 rgba(0,0,0,0.02), 0 2px 6px 6px rgba(0,0,0,0.02), 0 2px 6px 0 rgba(0,0,0,0.06);
@box-shadow-3: 0 0 8px 0 rgba(0,0,0,0.04), 0 6px 12px 12px rgba(0,0,0,0.04), 0 6px 10px 0 rgba(0,0,0,0.08);
@box-shadow-4: 0 4px 14px 0 rgba(0,0,0,0.04), 0 12px 16px 16px rgba(0,0,0,0.04), 0 10px 16px 0 rgba(0,0,0,0.08);

// colors
@primary-color: @blue-6;
@info-color: @primary-color;
@success-color: @green-6;
@processing-color: @blue-6;
@error-color: @red-6;
@highlight-color: @red-6;
@warning-color: @orange-6;
@normal-color: #d9d9d9;
@white: #fff;
@black: @gray-9;
@heading-color: @gray-9;
@text-color: @gray-base;
@text-color-secondary: @gray-7;
@disabled-color: @gray-5;
@picker-basic-cell-hover-color: @blue-1;
@heading-color: @gray-base;

@font-size-xs: 12px;
@font-size-base: 14px;
@font-size-sm: 16px;
@font-size-md: 18px;
@font-size-lg: 20px;
@font-size-hg: 28px;

@border-radius-xxs: 2px;
@border-radius-xs: 4px;
@border-radius-sm: 6px;
@border-radius-md: 8px;
@border-radius-lg: 10px;
@border-radius-base: @border-radius-xs;

@border-color-base: @gray-3;

@separator-color-base: #f2f2f2;
@separator-color-dark: #d8d8d8;

// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

@background-color-light: #fff;
@background-color-base: #f7f7f7;
@background-color-hover: @gray-1;

@btn-line-height: 1;
@btn-font-size-lg: @font-size-sm;
@btn-font-size-sm: @font-size-xs;
@btn-padding-vertical: 5px;
@btn-padding-horizontal: 12px;

@alert-warning-background-color: #fff8e6;
@alert-warning-message-color: #662b00;
@alert-padding: 7px 12px;

@input-padding-vertical-lg: @padding-xs;
@input-padding-horizontal-lg: @padding-xs;
@input-padding-vertical-base: 5px;
@input-padding-horizontal-base: @padding-xs;
@input-placeholder-color: @gray-4;

@layout-header-height: 56px;
@layout-header-padding: 0 16px 0 20px;
@layout-header-background: @background-color-light;
@layout-header-color: @gray-base;

@modal-body-padding: 16px 24px;
@modal-header-title-line-height: 26px;
@modal-header-title-font-size: @font-size-md;

@result-subtitle-font-size: @font-size-sm;
@result-title-font-size: 24px;
@result-subtitle-font-size: @font-size-sm;

@switch-min-width: 32px;
@switch-height: 20px;
@switch-sm-height: 16px;
@switch-pin-size: 16px;

@form-item-margin-bottom: @margin-md;
@label-color: @gray-7;

@table-header-bg: @background-color-base;
@table-header-color: @gray-7;
@table-padding-vertical: 7px;
@table-padding-horizontal: @padding-sm;
@table-border-color: @separator-color-base;

@tabs-title-font-size: @font-size-sm;
@tabs-horizontal-padding: @padding-xss;
@tabs-bar-margin: 0 0 16px 0;

@tag-font-size: @font-size-xs;

@picker-panel-cell-height: 30px;

@divider-color: #d8d8d8;

@menu-item-boundary-margin: 0;
@menu-item-vertical-margin: 0;

@alert-info-border-color: @blue-2;

@slider-handle-size: 20px;
@slider-handle-margin-top: -8px;
@slider-handle-shadow: 0 0 0 0 rgba(0,0,0,0.15), 0 1px 0 0 rgba(0,0,0,0.05);
@slider-track-background-color: @blue-base;
@slider-rail-background-color: @gray-3;
