@import '~@yeastar/pc-web-ui/src/style/variables.less';

.view-btn{
    color: @blue-base;
    font-size: 12px;
    cursor: pointer;
    transition: color .3s;
    &:hover {
        color: @blue-5;
    }
}
