.BannedPage-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .app-text {
        margin-top: 16px;
        font-size: 16px;
        color: rgba(0, 0, 0, .6);
        letter-spacing: 0;
        text-align: center;
    }
    .app-logo {
        width: 320px;
    }
}
