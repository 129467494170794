.icon-font.anticon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: rgba(0,0,0,0.04);
        border-radius: 50%;
    }
    &:active {
        background-color: rgba(0,0,0,0.07);
    }
}
