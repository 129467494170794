.period-conflict-meeting-list {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    &-header {
        padding: 8px;
        border-bottom: 1px solid #e5e5e5;
    }
    &-wrapper {
        max-height: 100px;
        padding: 6px 30px;
        overflow: auto;
        color: rgba(0, 0, 0, 0.38);
        font-size: 12px;
    }
    &-item {
        position: relative;
        padding: 6px 0;
        line-height: 16px;
        &::before {
            position: absolute;
            top: 50%;
            left: -13px;
            width: 4px;
            height: 4px;
            background: rgba(0, 0, 0, 0.13);
            border-radius: 50%;
            transform: translateY(-50%);
            content: '';
        }
    }
}

.period-conflict-meeting-list {
    &--hidden {
        border: none;
        .period-conflict-meeting-list {
            &-wrapper {
                display: none;
            }
            &-header {
                padding: 0;
                border: none;
            }
        }
    }
}
