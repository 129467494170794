.tour-popover {
    position: relative;
    width: 320px;
    padding: 16px 24px !important;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 #00000005, 0 4px 6px 6px #00000005, 0 4px 6px 0 #0000000f;
    .tour-popover-header-close {
        float: right;
        .anticon  {
            padding: 2px 0;
            color: #fff;
        }
    }
    &-header {
        &-title {
            margin-right: 24px;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
    &-content {
        &-video {
            height: 160px;
            margin-top: 8px;
            padding-bottom: 4px;
            video {
                display: block;
                width: 100%;
                border-radius: 4px;
            }
        }
        &-segmented {
            display: flex;
            margin-top: 8px;
            .segmented-block {
                width: 26px;
                height: 4px;
                margin-right: 8px;
                background: #ffffff61;
                border-radius: 4px;
            }
            .segmented-active {
                background: #fff;
            }
        }
    }
    &-footer {
        margin-top: 12px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

#___reactour {
    .reac-tour-popover {
        z-index: 1050;
    }
}
