@import '~@yeastar/pc-web-ui/src/style/variables.less';
.YS-PC-Calendar-Header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .marginBottom12 {
        margin-bottom: 12px;
    }

    .meeting-name {
        width: 100%;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;
        .fill-start-icon {
            color: @orange-base;
        }
        .star-icon {
            color: #a5a5a5;
        }
    }

    .meeting-number {
        width: 100%;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        word-break: break-all;
        filter: blur(10);
        .ant-tag {
            margin-right: 4px;
            margin-bottom: 4px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .meeting-number.show-left,
    .meeting-name.show-left {
        margin-bottom: 2px;
        text-align: left;
    }
    .meeting-calendar-email {
        font-size: 12px;
    }

    .meeting-name.show-left {
        position: relative;
        height: 24px;
        margin-bottom: 7px;
        .Icon-content {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .meeting-name-title {
        display: inline-block;
        max-width: 320px;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .so-plan-lock {
        display: inline-block;
        max-width: 320px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .meeting-name-title {
            display: inline;
            color: inherit;
        }
    }

    .meeting-inline-box {
        display: flex;
        max-width: 320px;
        margin-top: 12px;
        .icon-class {
            margin-right: 8px;
            font-size: 16px;
        }
        .ant-typography {
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .meeting-address-show {
        display: inline-block;
        max-width: 290px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .meeting-device-state {
        // margin:0 0 12px 0;
    }
}

.CalendarHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    cursor: pointer;

    .pag-left,
    .pag-right {
        position: absolute;
        display: none;
    }

    .pag-left {
        left: -16px;
    }

    .pag-right {
        right: -16px;
    }

    .meet-room-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 8px 8px 6px 8px;
        .meeting-name-wrapper {
            width: 100%;
            .meeting-name {
                max-width: calc(100% - 18px);
                overflow: hidden;
                color: rgba(0, 0, 0, 0.87);
                font-size: 14px;
                line-height: 22px;
                white-space: nowrap;
                text-align: left;
                text-overflow: ellipsis;
            }
        }
    }

    .meet-room-header.ant-popover-open {
        background: rgba(0, 0, 0, 0.04);
    }
}

.rbc-time-header-content:nth-child(2) .CalendarHeader .PaginationBtn:first-child {
    display: flex;
}

.rbc-time-header-content:last-child .CalendarHeader .PaginationBtn:last-child {
    display: flex;
}

.meet-popover {
    min-width: 320px;
    min-height: 121px;
    padding: 0;

    .ant-popover-inner-content {
        padding: 24px;
        max-height: 420px;
        overflow: auto;
    }

    .ant-popover-arrow {
        display: none !important;
    }
}
.week-new-calender,
.month-new-calender {
    .rbc-time-view {
        .rbc-time-header {
            .rbc-header {
                .CalendarHeader {
                    height: 100%;
                }
            }
        }
    }
}
